import Course from './Course';
import User, { MinimalUser } from './User';

export default interface CourseInstance {
  id: number;
  course: Course;
  orderId: number;
  createdBy?: MinimalUser;
  startsAt?: string;
  endsAt?: string;
  contactPerson?: ContactPerson;
  instructor?: MinimalUser | null;
  status?: CourseInstanceStatus;
  subsidiaryCompanyName?: string;
  clientName?: string;
  price?: number;
  address?: string;
  placeId?: string;
  lat?: number;
  lng?: number;
  comment?: string;
  equipment?: string;
  elsaTrainingSessionId?: string;
  elsaEnrollmentShareUrl?: string;
  hasPrerequisiteDigitalCourse?: boolean;
  participantLimit?: number;
  responsibleBooker?: MinimalUser;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export type ContactPerson = {
  id: number;
  firstName: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  note?: string;
};

export interface CourseInstanceCardData {
  id: CourseInstance['id'];
  courseName?: string;
  subsidiaryCompanyName?: string;
  clientName?: string;
  startsAt?: CourseInstance['startsAt'];
  endsAt?: CourseInstance['endsAt'];
  instructorId?: User['id'];
  status: CourseInstance['status'];
  address?: CourseInstance['address'];
}

export enum CourseInstanceStatus {
  Canceled = 'canceled',
  Preliminary = 'preliminary',
  Confirmed = 'confirmed',
}

export enum CourseInstanceAttendeeStatus {
  Passed = 'Passed',
  Failed = 'Failed',
  DidNotParticipate = 'DidNotParticipate',
}

export type CourseInstanceAttendee = {
  id?: number;
  elsaId?: string;
  name: string;
  email?: string;
  status?: CourseInstanceAttendeeStatus;
  comment?: string;
  prerequisiteDigitalCourseCompletedPercentage?: number;
};

export interface CourseInstanceAttendeeComplete extends CourseInstanceAttendee {
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}
